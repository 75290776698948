<template>
  <div class="bio_card" :class="(card.animation_enabled) ? getAnimation(card.animation) : ''">
    <div :class="{'apply_radius':card.title || card.description}" v-if="!card.url && !card.image"
         class="default_card text-white">
      <i class="fas fa-image d-flex align-items-center justify-content-center"></i>
    </div>

    <div v-else :class="{'apply_radius':card.title || card.description}"
         :style="{ backgroundImage: `url('${card.image}')`,paddingTop: card.style === 'card-2' ? '250px': '120px'}"
         class="image"></div>
    <div v-if="card.title || card.description" class="d-block p-2 title"
         :style="bioSectionThemeStyleBinding('background',getBioLinkAdds)">
      <p :style="bioSectionThemeStyleBinding('text',getBioLinkAdds)">{{ card.title }}</p>
      <p :style="bioSectionThemeStyleBinding('text',getBioLinkAdds)"><small>{{ card.description }}</small></p>
    </div>
  </div>
</template>
<script>


export default ({
  props: [
    'isPreview',
    'card',
    'getBioLinkAdds'
  ],
  methods: {},
})
</script>
<style lang="less" scoped>
.bio_card {
  border-radius: 10px;

  .default_card {
    padding-top: 70px;
    font-size: xxx-large;
    height: 199px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.35);
  }

  .image {
    background-color: transparent;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }

  .apply_radius {
    border-radius: 10px 10px 0px 0px;
  }

  .title {
    background: whitesmoke;
    text-align: center;
    border-radius: 0px 0px 10px 10px;
    font-weight: 600;
    font-size: 15px;
    word-break: break-word;
    word-wrap: break-word;
  }
}
</style>
